<script>
import BigMenu from '@/components/general/BigMenu'
export default {
  name: 'AdminCenter',
  components: {
    BigMenu
  },
  data () {
    return {
      menuOptions: [
        { title: 'admin.center:menu.1:title', description: 'admin.center:menu.1:description', icon: 'mdi-file-document-outline', to: { name: 'business.admin.center.company.data' } },
        { title: 'admin.center:menu.2:title', description: 'admin.center:menu.2:description', icon: 'mdi-label', to: { name: 'business.admin.center.bookmarks' } },
        { title: 'admin.center:menu.3:title', description: 'admin.center:menu.3:description', icon: 'mdi-account', to: { name: 'business.admin.center.users' } },
        { title: 'admin.center:menu.4:title', description: 'admin.center:menu.4:description', icon: 'mdi-account-multiple', to: { name: 'business.admin.center.departments' } },
        { title: 'admin.center:menu.5:title', description: 'admin.center:menu.5:description', icon: 'mdi-merge', to: { name: 'business.admin.center.advertising.integrations' } },
        { title: 'admin.center:menu.6:title', description: 'admin.center:menu.6:description', icon: 'mdi-cog', to: { name: 'business.admin.center.support' } }
        // { title: 'admin.center:menu.7:title', description: 'admin.center:menu.7:description', icon: 'mdi-cash', to: { name: 'business.admin.center.payment' } }
      ]
    }
  },
  computed: {
    company () {
      return this.selectedWorkspace
    }
  }
}
</script>
<template>
  <section class="admin-center--container center-small">
    <div class="default-home-title">
      <div>
        <img :src="company.avatar" :alt="$t(company.name)" v-if="company.avatar" />
        <h2>{{ company.name }}</h2>
      </div>
      <p>{{ $t('admin.center:description') }}</p>
    </div>
    <div class="admin-center--menu">
      <big-menu :items="menuOptions"></big-menu>
      <a :href="'/c/' + company.subdomain + '/hire/config/wallet'" class="btn-temp">
        <div class="big-menu--custom-btn">
          <div class="big-menu--icon-wrapper">
            <i aria-hidden="true" class="v-icon notranslate mdi mdi-cash theme--light"></i>
          </div>
          <div class="big-menu--text">
            <p class="title">{{ $t('admin.center:menu.7:title') }}</p>
            <span class="description">{{ $t('admin.center:menu.7:description') }}</span>
          </div>
        </div>
      </a>
    </div>
  </section>
</template>
<style lang="scss">
  .admin-center--container {
    .btn-temp {
      text-decoration: none;
      display: block;
      width: 50%;
      margin-bottom: 24px;

      .big-menu--custom-btn {
          display: flex;
          align-items: center;
          padding: 16px;
          background: #FFFFFF;
          border: 1px solid rgba(0, 0, 0, 0.06);
          border-radius: 4px;
          width: calc(100% - 12px);
          &:hover {
              background: #eaeaea;
          }
          .big-menu--icon-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 48px;
              width: 48px;
              margin-right: 16px;
              .v-icon {
                font-size: 24px;
                color: rgb(69, 69, 69);
                caret-color: rgb(69, 69, 69);
              }
          }
          .big-menu--text {
              display: flex;
              flex-direction: column;
              text-align: left;
              p.title {
                  font-family: 'Lato';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 16px;
                  color: #212121;
                  margin-bottom: 8px;
              }
              .description {
                  font-family: 'Lato';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 14px;
                  color: #A0A0A0;
              }
          }
      }
    }
    @media only screen and (max-width: 800px) {
      .big-menu--container {
        & > a {
          flex: 1;
          width: 100%;
        }
      }
      .btn-temp {
        flex: 1;
        width: 100%;
      }
    }
  }
</style>
