<script>
export default {
  name: 'BigMenu',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<template>
  <div class="big-menu--container">
    <router-link v-for="link in items" :to="{ name: link.to.name, params: { ...$route.params, ...link.to.params } }" tag="a" :key="link.to.name">
      <div class="big-menu--custom-btn">
        <div class="big-menu--icon-wrapper">
          <v-icon color="#454545" size="24px">{{ link.icon }}</v-icon>
        </div>
        <div class="big-menu--text">
          <p class="title">{{ $t(link.title) }}</p>
          <span class="description">{{ $t(link.description)}}</span>
        </div>
      </div>
    </router-link>
  </div>
</template>
<style lang="scss">
.big-menu--container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > a {
    text-decoration: none;
    flex: 0 50%;
    width: calc(50% - 12px);
    margin-bottom: 24px;
  }
  .big-menu--custom-btn {
    display: flex;
    align-items: center;
    padding: 16px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    width: calc(100% - 12px);
    &:hover {
      background: #EAEAEA;
    }
    .big-menu--icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      width: 48px;
      margin-right: 16px;
    }
    .big-menu--text {
      display: flex;
      flex-direction: column;
      text-align: left;
      p.title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        color: #212121;
        margin-bottom: 8px;
      }
      span.description {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #A0A0A0;
      }
    }
  }
}
</style>
